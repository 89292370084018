<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('tix.title')"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div class="maincontent">
			<van-tabs v-model="form.withdraw_type" type="card" color="#004ea3">
			  <van-tab :title="$t('加密货币')">
					<van-form v-model="form">
					  <van-row>
					    <van-cell :title="$t('tix.biz')" center>
					      <template #extra>
					        <van-dropdown-menu active-color="#004ea3">
					          <van-dropdown-item v-model="bizhong" :options="bzoptions" />
					        </van-dropdown-menu>
					      </template>
					    </van-cell>
					    <van-cell :title="$t('tix.lname')" center>
					      <template #extra>
					        <van-dropdown-menu active-color="#004ea3">
					          <van-dropdown-item v-model="productvalue" :options="product" />
					        </van-dropdown-menu>
					      </template>
					    </van-cell>
					    <van-field
							:disabled="userWithdrawInfo.hasOwnProperty('usdt_address') && userWithdrawInfo.usdt_address.length > 0"
					      v-model="form.url"
					      :label="$t('tix.address')"
					      :placeholder="$t('tix.enter')"
					    />
					    <van-field :label="$t('tix.number')" v-model="form.number" clearable
					      ><template #button>
					        <van-button size="small" type="primary" @click.prevent="all">{{
					          $t('tix.allbtn')
					        }}</van-button>
					      </template>
					    </van-field>
					    <div
					      style="width: 100%; text-align: left; padding-left: 13px; margin: 10px 0px;"
					    >
					      <span style="color: rgb(66, 76, 102);">
									{{ $t('tix.text') }}{{ balance }}
									</span>
							<div>
								<span style="color: rgb(66, 76, 102);"
									>{{ $t('手续费') }}:{{ config.withdrawl_fee }}%</span
								>
							</div>
					    </div>
					  </van-row>
					  <van-button class="submitBtns" @click.prevent="submit">{{
					    $t('common.submit')
					  }}</van-button>
					</van-form>
					
				</van-tab>
			  <van-tab :title="$t('银行卡')">
					
					<van-form v-model="form">
					  <van-row>

					    <van-field :disabled="userWithdrawInfo.hasOwnProperty('bank_username') && userWithdrawInfo.bank_username.length > 0"
					      v-model="form.bank_username"
					      :label="$t('开户姓名')"
					      :placeholder="$t('开户姓名')"
					    />
							<van-field :disabled="userWithdrawInfo.hasOwnProperty('bank_account') && userWithdrawInfo.bank_account.length > 0"
							  v-model="form.bank_account"
							  :label="$t('银行卡账号')"
							  :placeholder="$t('银行卡账号')"
							/>
							<van-field :disabled="userWithdrawInfo.hasOwnProperty('bank_name') && userWithdrawInfo.bank_name.length > 0"
							  v-model="form.bank_name"
							  :label="$t('开户银行')"
							  :placeholder="$t('开户银行')"
							/>
							<van-field :disabled="userWithdrawInfo.hasOwnProperty('bank_branch_name') && userWithdrawInfo.bank_branch_name.length > 0"
							  v-model="form.bank_branch_name"
							  :label="$t('开户支行')"
							  :placeholder="$t('开户支行')"
							/>
							
							
					    <van-field :label="$t('tix.number')" v-model="form.number" clearable
					      ><template #button>
					        <van-button size="small" type="primary" @click.prevent="all">{{
					          $t('tix.allbtn')
					        }}</van-button>
					      </template>
					    </van-field>
					    <div
					      style="width: 100%; text-align: left; padding-left: 13px; margin: 10px 0px;"
					    >
					      <span style="color: rgb(66, 76, 102);">
									{{ $t('tix.text') }}{{ balance }}
									</span>
							<div>
								<span style="color: rgb(66, 76, 102);"
									>{{ $t('手续费') }}:{{ config.withdrawl_fee }}%</span
								>
							</div>
					    </div>
					  </van-row>
					  <van-button class="submitBtns" @click.prevent="submit">{{
					    $t('common.submit')
					  }}</van-button>
					</van-form>
					
				</van-tab>
			</van-tabs>
			

    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
				withdraw_type: 0,
        url: '',
        number: 0,
				bank_username: '',
				bank_account: '',
				bank_name: '',
				bank_branch_name: ''
      },
			
      productvalue: 0,
      product: [],
      bizhong: 1,
      bzoptions: [{ text: 'USDT', value: 1 }],
      balance: 0,
      config: {},
			userWithdrawInfo: {
				bank_username: '',
				bank_account: '',
				bank_name: '',
				bank_branch_name: '',
				usdt_address: ''
			}
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    async getinfo() {
      const { data } = await this.$http.get('/home/user/withdraw')
      if (data) {
        if (data.code === 200) {
          this.balance = data.data.balance
          this.wallet = data.data.type
          this.config = data.data.config
					this.userWithdrawInfo = data.data.userWithdrawInfo
					if (this.userWithdrawInfo.usdt_address) {
						this.form.url = this.userWithdrawInfo.usdt_address
					}
					if (this.userWithdrawInfo.bank_username) {
						this.form.bank_username = this.userWithdrawInfo.bank_username
						this.form.bank_account = this.userWithdrawInfo.bank_account
						this.form.bank_name = this.userWithdrawInfo.bank_name
						this.form.bank_branch_name = this.userWithdrawInfo.bank_branch_name
					}
          this.product = []
          this.wallet.forEach((type, index) => {
            this.product.push({
              text: type.wallet_type.toUpperCase(),
              value: index
            })
          })
          if (this.config.withdrawl_opend === '0') {
            this.$toast.fail(this.$t('tix.erroropen'))
          }
          if (!this.config.attime) {
            this.$toast.fail(
              this.$t('tix.errortime') + this.config.withdrawl_times
            )
          }
        }
      }
    },
    all() {
      this.form.number = this.balance
    },
    async submit() {
			if (this.form.withdraw_type == 0) {
				if (!this.form.url || !this.form.number) {
				  this.$toast.fail(this.$t('tix.complate'))
				  return false
				}
			} else {
				if (!this.form.bank_username || 
				!this.form.bank_account ||
				!this.form.bank_name ||
				!this.form.bank_branch_name ||
				!this.form.number) {
				  this.$toast.fail(this.$t('tix.complate'))
				  return false
				}
			}

      const postform = {
        product_type: this.wallet[this.productvalue].product_type,
        product_type_id: this.wallet[this.productvalue].product_type_id,
				wallet_type: this.wallet[this.productvalue].wallet_type,
				wallet_type_id: this.wallet[this.productvalue].wallet_type_id,
        withdraw_url: this.form.url,
        withdraw_num: this.form.number,
        service_charge: this.config.withdrawl_fee,
				withdraw_type: this.form.withdraw_type,
				bank_username: this.form.bank_username,
				bank_account: this.form.bank_account,
				bank_name: this.form.bank_name,
				bank_branch_name: this.form.bank_branch_name,
      }
      const { data } = await this.$http.post('/home/user/withdraw', postform)
      if (data) {
        if (data.code === 200) {
          this.$toast.success(this.$t('tix.submitok'))
          // this.form = {
          //   url: '',
          //   number: 0
          // }
					this.form.number = 0
					
          this.getinfo()
        } else {
						let msg = this.getlang(data.msg, localStorage.getItem('lang'))
						for (let key in data.data) {
							if (data.data.hasOwnProperty(key)) {
								msg = msg.replace(key, data.data[key])
							}
						}
						this.$toast.fail(msg)
          
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.maincontent {
  padding-top: 65px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #f7f7f7;
  height: 100vh;
  .van-cell__title {
    text-align: left;
    font-size: 0.93333rem;
  }
  .van-cell {
    font-size: 0.98667rem;
    background-color: #fff !important;
    height: 3.46667rem;

    &:after {
      border-bottom: 0.02667rem solid #d0d0d1;
    }
  }
  .van-dropdown-menu /deep/.van-dropdown-menu__bar {
    box-shadow: none;
    height: 1rem;
    background: transparent !important;
  }
  .van-dropdown-menu /deep/ .van-dropdown-menu__title {
    color: #212121 !important;
  }
  /deep/.van-dropdown-item {
    margin-top: 1.1rem;
  }
  .van-field {
    height: 3.4rem;
    font-size: 0.93333rem;
    /deep/.van-field__label {
      color: #323232;
    }
  }
  .van-cell__value {
    position: relative;
    overflow: hidden;
    color: #969799;
    text-align: right;
    vertical-align: middle;
    word-wrap: break-word;
  }
  /deep/.van-field__error-message--right,
  /deep/.van-field__control,
  /deep/.van-field__error-message {
    text-align: right;
  }
  .message {
    margin-top: 0.8rem;
    font-size: 1.06667rem;
    color: #000;
  }
  .submitBtns {
    margin-top: 70%;
		margin-top: 50px;
    width: 80%;
    letter-spacing: 0.53333rem;
    height: 4rem;
    font-size: 0.93333rem;
    color: #fff;
    background-color: #004ea3;
    border-radius: 0.53333rem;
    border: none;
    box-shadow: 0 0 1.33333rem #004ea3;
  }
}
</style>
